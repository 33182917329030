<template>
  <div class="custom-input" ref="inputField">
    <label class="input-label" v-if="$validate.DataValid(label)">{{ $t(label) }}</label>
    <v-menu ref="colorPickerMenu" v-model="colorPickerOpen" offset-y :disabled="disabled">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          persistent-placeholder
          :value="$validate.DataValid(selectedColor) ? 'Aa' : ''"
          v-bind="attrs"
          v-on="on"
          readonly
          outlined
          single-line
          :rules="[v => (required ? $validate.required(v) || $t('message.formRequired') : true)]"
          :placeholder="$t(placeholder)"
          dense
          :background-color="$validate.DataValid(selectedColor) ? selectedColor : '#ffffff'"
          :class="selectedColor === '#A7FF00' || selectedColor === '#2ED8F7' ? 'colorBlack--text' : 'colorWhite--text'"
        >
          <template v-slot:append>
            <v-icon
              class="fi fi-rr-angle-small-down mt-1"
              size="18"
              :color="
                $validate.DataValid(selectedColor)
                  ? selectedColor === '#A7FF00' || selectedColor === '#2ED8F7'
                    ? 'colorBlack'
                    : 'colorWhite'
                  : 'rgba(94, 86, 105, 0.68)'
              "
            ></v-icon>
          </template>
        </v-text-field>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item, i) in colorOptions"
          :key="i"
          :value="item"
          @click="updateValueOnChange(item)"
          :style="{ background: item }"
          :class="item === '#A7FF00' || item === '#2ED8F7' ? 'colorBlack--text' : 'colorWhite--text'"
        >Aa</v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import FormSelect from '@/components/formField/FormSelect.vue'

export default {
  name: 'CourseColorPicker',
  components: {
    FormSelect,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    fieldValue: {
      type: String,
      required: true,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    fieldValue: {
      handler(val) {
        this.selectedColor = val;
      },
      immediate: true
    }
  },
  data: () => ({
    colorPickerOpen: false,
    selectedColor: '',
    colorOptions: [
      '#525353',
      '#1B718A',
      '#E25D0A',
      '#CB7500',
      '#0A0ABC',
      '#03B1B1',
      '#A80B9C',
      '#50B71A',
      '#08C995',
      '#A7FF00',
      '#2ED8F7',
    ],
  }),
  methods: {
    updateValueOnChange(val) {
      this.selectedColor = val
      this.$emit('update:fieldValue', val)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-text-field input {
  color: inherit !important;
}

::v-deep .v-input.error--text {
  color: var(--v-error-base) !important;
  caret-color: var(--v-error-base) !important;
}
</style>
