var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"inputField",staticClass:"custom-input"},[(_vm.$validate.DataValid(_vm.label))?_c('label',{staticClass:"input-label"},[_vm._v(_vm._s(_vm.$t(_vm.label)))]):_vm._e(),_c('v-menu',{ref:"colorPickerMenu",attrs:{"offset-y":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.selectedColor === '#A7FF00' || _vm.selectedColor === '#2ED8F7' ? 'colorBlack--text' : 'colorWhite--text',attrs:{"persistent-placeholder":"","value":_vm.$validate.DataValid(_vm.selectedColor) ? 'Aa' : '',"readonly":"","outlined":"","single-line":"","rules":[function (v) { return (_vm.required ? _vm.$validate.required(v) || _vm.$t('message.formRequired') : true); }],"placeholder":_vm.$t(_vm.placeholder),"dense":"","background-color":_vm.$validate.DataValid(_vm.selectedColor) ? _vm.selectedColor : '#ffffff'},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{staticClass:"fi fi-rr-angle-small-down mt-1",attrs:{"size":"18","color":_vm.$validate.DataValid(_vm.selectedColor)
                ? _vm.selectedColor === '#A7FF00' || _vm.selectedColor === '#2ED8F7'
                  ? 'colorBlack'
                  : 'colorWhite'
                : 'rgba(94, 86, 105, 0.68)'}})]},proxy:true}],null,true)},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.colorPickerOpen),callback:function ($$v) {_vm.colorPickerOpen=$$v},expression:"colorPickerOpen"}},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.colorOptions),function(item,i){return _c('v-list-item',{key:i,class:item === '#A7FF00' || item === '#2ED8F7' ? 'colorBlack--text' : 'colorWhite--text',style:({ background: item }),attrs:{"value":item},on:{"click":function($event){return _vm.updateValueOnChange(item)}}},[_vm._v("Aa")])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }